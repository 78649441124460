<template>
  <div class="d-flex flex-column">
    <section class="wet-section">
      <div class="container-lg">
        <h3 class="empty-ph is-size-6">
          {{ title }}
        </h3>
        <div class="border-y-between">
          <crop-selection-item
            :ref="`selection-item-${item.id}`"
            v-for="item in crops"
            :key="item.id"
            :title="item.title"
            :crop="item.id"
            :image="item.image"
            alt
            bundle
            v-bind="selectedBind[item.id]"
            v-on="selectedOn[item.id]"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import common from '@/mixins/common';
import OrderCropItem from '@/models/order-crop-item';
import partnerCodeMixin from '@/mixins/partner-code';
import wpSection from '@/enums/wpSection';

export default {
  name: 'DroughtBundle',
  components: {
    cropSelectionItem: () => import(/* webpackChunkName: "cropSelectionItem" */ '@/components/crop-selection/cropSelectionItem.vue'),
  },
  mixins: [common, partnerCodeMixin],
  data() {
    return {
      wpApiCropSelection: '/wp-json/acf/v3/options/crop-selection',
      wpDroughtPath: '/wp-json/acf/v3/options/drought', /** for get crop images */
      orderBundlePostApi: '/api/bundle/order',
      selected: {},
      someItemsInvalid: false,
    };
  },
  computed: {
    ...mapState(['strings', 'cropSelected', 'isMobile']),
    ...mapState({
      crops(state) {
        return [
          ...state.settings.crops.map((c) => ({
            ...c,
            image: this.images[c.id]?.photo,
            title: this.images[c.id]?.title,
          })),
        ].filter((c) => this.strings
          .drought?.crops?.filter((cc) => cc.id === c.id)[0]?.crop_selection);
      },
    }),
    images() {
      const images = this.strings
        .drought?.crops?.map((c) => [c.id, { photo: c.photo, title: c.title }]);
      return Object.fromEntries(images || []);
    },
    selectedBind() {
      return Object.fromEntries(this.crops?.map((c) => ([
        c.id,
        {
          defaultStartDate: c.default_start,
          defaultEndDate: c.default_end,
          defaultCropValuePerHectar: c.default_crop_value_per_hectar,
          defaultArea: c.default_area,
          ...new OrderCropItem(this.selected[c.id]),
        },
      ])) || []);
    },
    selectedOn() {
      return Object.fromEntries(this.crops?.map((c) => ([
        c.id,
        {
          'update:area': (v) => this.$set(this.selected, c.id, { ...this.selected[c.id], area: v }),
          'update:startDate': (v) => this.$set(this.selected, c.id, { ...this.selected[c.id], startDate: v }),
          'update:endDate': (v) => this.$set(this.selected, c.id, { ...this.selected[c.id], endDate: v }),
          'update:perHectar': (v) => this.$set(this.selected, c.id, { ...this.selected[c.id], perHectar: v }),
          invalid: (v) => { this.someItemsInvalid = v; },
        },
      ])) || []);
    },
    disabledNext() {
      return Object.keys(this.cropSelected).length === 0 || this.someItemsInvalid;
    },
  },
  watch: {
    selected: {
      immediate: true,
      deep: true,
      handler(val) {
        const selected = this.$lodash.pickBy(val, (el) => !!el.area);
        if (!this.$lodash.isEqual(selected, this.cropSelected)) {
          this.$store.commit('SET_CROP_SELECTED', selected);
        }
      },
    },
    disabledNext: {
      handler(val) {
        this.$emit('update:nextDisabled', val);
      },
    },
  },
  async created() {
    await Promise.all([
      this.$emit('update:nextDisabled', true),
      this.$root.$on('buttonNext', this.next),
      this.$root.$on('buttonBack', this.back),
      this.getWordPressStrings(
        [wpSection.DROUGHT, wpSection.CROP_SELECTION],
      ),
      this.getCrops(),
    ]);
  },
  destroyed() {
    // unwatch next & back buttons click watcher
    this.$root.$off('buttonNext', this.next);
    this.$root.$off('buttonBack', this.back);

    // set next button title
    this.$emit('update:nextTitle', '');
  },
  methods: {
    async getCrops() {
      /** get crops */
      await this.$store.dispatch('settings/getProductSettings', { site: 'drought' });
    },
    validate() {
      const valid = Object.keys(this.cropSelected).every((cropId) => {
        const el = this.$refs[`selection-item-${cropId}`]?.[0];
        return el && el.validate();
      });
      return valid;
    },
    async saveCropSelection() {
      const data = {
        crops: Object.entries(this.cropSelected).map(([type, c]) => ({
          type,
          hectar: c.area,
          start: c.startDate,
          end: c.endDate,
          value_per_hectar: c.perHectar,
        })),
        partner_code: this.partnerCode,
      };
      if (data.crops.some((c) => [c.start, c.end].includes('Invalid date'))) {
        return Promise.reject(new Error('Invalid date'));
      }
      return this.callApi({ url: this.orderBundlePostApi, method: 'POST', data });
    },
    async next() {
      // next button handler
      // check validation
      if (!this.validate()) {
        // this.initIntro();
        return;
      }

      await this.saveCropSelection().then(() => {
        this.$router.push({ name: 'location', params: { ...this.$route.params } });
      });

      // go to next page
      // this.$router.push({ name: 'crop-location', params: { ...this.$route.params } });
    },
    back() {
      //
    },
  },
};
</script>

<style lang="scss">

</style>
